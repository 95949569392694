 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="流水编号">
				<el-input class="el_input" v-model="form.ra_num" placeholder="流水编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="操作人手机">
				<el-input class="el_input" v-model="form.admin_user_tel" type="number" placeholder="操作人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="操作人姓名">
				<el-input class="el_input" v-model="form.admin_user_name" placeholder="操作人姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="充值类型">
				<el-select class="el_input" v-model="form.balance_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="对公余额" value="1"></el-option>
					<el-option label="对私余额" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="充值时间">
				<div class="block">
				<el-date-picker
					v-model="recharg_time"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item">
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" style="background-color:#795A25;border: none;" @click="page_ser">查询</el-button>
				<el-button type="primary" style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="ser_para_clear">清空条件</el-button>
				<el-button type="primary" style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="recharge_export">导出</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading"  @selection-change="recharge_choose">
				<el-table-column type="selection" width="39px"></el-table-column>
				<el-table-column label="流水编号" width="200px">
					<template slot-scope="scope">
						<span class="tab_line_item">{{scope.row.ra_num}}</span>
					</template>
				</el-table-column>
                <el-table-column label="充值时间" width="205px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作人信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.admin_user_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="充值信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.much}}元/{{scope.row.balance_type_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="备注" prop="mark"></el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
	</div>
</template>

<script>
import { Loading } from "element-ui";
	export default {
		data() {
			return {

				//搜索条件
				form: {				  
					ra_num:'',//流水编号
					admin_user_tel:'',//充值人员手机
					admin_user_name:'',//充值人员姓名
					balance_type:'',//余额类型
					mark:'',//备注
					creat_time_start:'',//充值开始时间
					creat_tiem_end:''//充值结束时间
				},
				recharg_time:'',//充值时间
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				//快捷时间选择器
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				recharge_choose_list:'',//勾选数据
			}
		},
		created() {

			//取出参数
			let query=this.$route.query;
			for(var i in query){
				this.form[i]=query[i]
			}
			
			//读取页面数据
			this.get_page_data()
		},
		methods: {
			//勾选
			recharge_choose(event){
				this.recharge_choose_list=event
			},
			//导出
			recharge_export(){
				//未勾选数据
				if(this.recharge_choose_list.length == 0){
					this.$my.other.confirm({
						content:"当前未勾选数据,是否全部导出",
						confirm:()=>{
							//读取数据
							this.$my.net.req({
								data:{
									mod:'finance',
									ctr:'recharge_list_shipper',
									...this.form,
									num:1000
								},
								callback:(data)=>{

									//加载完毕
									this.list.loading=false

									//总数
									this.list.total=data.max

									//预处理
									for(let item of data.list){

										//下单时间
										item.creat_time_text=this.$my.other.totime(item.creat_time);

										//结算方式
										switch(item.balance_type){
											case '1':item.balance_type_text="对公余额";break;
											case '2':item.balance_type_text="对私余额";break;
										}
										if(item.admin_user_info.name){
											item.name = item.admin_user_info.name
										}else{
											item.name=''
										}
										if(item.admin_user_info.tel){
											item.tel = item.admin_user_info.tel
										}else{
											item.tel=''
										}
									}
									this.make_csv(data.list)
									Loading.service(this.options);
								}
							})
						}
					});
				}else{
					Loading.service(this.options);
					this.make_csv(this.recharge_choose_list)
				}
			},
			//生成csv
			make_csv(data){
				var shunxu=[
					{key:"ra_num",cname:"流水编号"},
					{key:"creat_time_text",cname:"操作时间"},
					{key:"name",cname:"操作人"},
					{key:"tel",cname:"操作人电话"},
					{key:"much",cname:"本次充值金额"},
					{key:"mark",cname:"备注"},
				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				for(var i=0;i<data.length;i++){
					for(var a in shunxu){
						str+=`${data[i][shunxu[a].key]}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "流水列表.xlsx";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				let loadingInstance = Loading.service(this.options);
					this.$nextTick(() => {
					loadingInstance.close();
				});
			},
			//清空查询条件
			ser_para_clear(){
				this.form={
					ra_num:'',//流水编号
					admin_user_tel:'',//充值人员手机
					admin_user_name:'',//充值人员姓名
					balance_type:'',//余额类型
					mark:'',//备注
					creat_time_start:'',//充值开始时间
					creat_time_end:''//充值结束时间
				}
				this.recharg_time=''
				this.page.p = 1
				this.get_page_data()
			},

			//搜索
			page_ser(){
				//时间处理
				if (this.recharg_time) {
					this.form.creat_time_start = parseInt(this.recharg_time[0] / 1000);
					this.form.creat_time_end= parseInt(this.recharg_time[1] / 1000);
				} else {
					this.form.creat_time_start = "";
					this.form.creat_time_end = "";
				}
				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'finance',
						ctr:'recharge_list_shipper',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//下单时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//结算方式
							switch(item.balance_type){
								case '1':item.balance_type_text="对公余额";break;
								case '2':item.balance_type_text="对私余额";break;
							}
							if(item.admin_user_info.name){
								item.name = item.admin_user_info.name
							}else{
								item.name=''
							}
							if(item.admin_user_info.tel){
								item.tel = item.admin_user_info.tel
							}else{
								item.tel=''
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 18%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin-left: 0;
	}
</style>